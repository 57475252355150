import { useHandleErrors } from '@/hooks/useHandleErrors';
import { useLocalStorageValue } from '@/hooks/useStorageValue';
import { noop } from '@/utils';
import { useQuery } from '@tanstack/react-query';
import {
  Html5Qrcode,
  Html5QrcodeCameraScanConfig,
  Html5QrcodeFullConfig,
  Html5QrcodeScannerState,
  Html5QrcodeSupportedFormats,
} from 'html5-qrcode';
import { useCallback, useEffect, useRef, useState } from 'react';

interface Device {
  id: string;
  label: string;
}

export const useDevicePool = () => {
  const { reportAsInfo } = useHandleErrors();
  const {
    data: devices,
    refetch,
    error,
    isError,
  } = useQuery({
    queryFn: () => Html5Qrcode.getCameras(),
    queryKey: ['devices'],
    refetchInterval: false,
  });

  if (isError) {
    reportAsInfo(error, ['useDevicePool', 'thrown during getting devices']);
  }

  useEffect(() => {
    const listener = () => refetch();
    navigator.mediaDevices.addEventListener('devicechange', listener);
    return () => {
      navigator.mediaDevices.removeEventListener('devicechange', listener);
    };
  }, [refetch]);
  return devices;
};

const getNext = <T>(items: ReadonlyArray<T>, current: T | undefined) => {
  if (items.length === 0) {
    return undefined;
  }
  if (current === undefined) {
    return items[0];
  }
  const currentIndex = items.indexOf(current);
  if (currentIndex === -1) {
    return items[0];
  }
  const nextIndex = (currentIndex + 1) % items.length;
  return items[nextIndex];
};

export const useDevice = () => {
  const devices = useDevicePool();
  const [device, setDevice] = useState<Device>();
  const { value: storedDeviceId, set: storeDeviceId } = useLocalStorageValue('last-used-scan-device', {
    defaultValue: '0',
    refetchInterval: false,
  });
  useEffect(() => {
    if (devices === undefined) {
      return;
    }
    if (devices.length === 0) {
      setDevice(undefined);
      return;
    }
    const deviceIds = devices.map(({ id }) => id);
    if (device !== undefined && deviceIds.includes(device.id)) {
      return;
    }
    if (storedDeviceId !== undefined && deviceIds.includes(storedDeviceId)) {
      setDevice(devices.find(({ id }) => id === storedDeviceId));
    } else {
      setDevice(devices[0]);
      // We deliberately don't store the device ID here. We only store it when
      // the user explicitly selects a device.
    }
  }, [devices, device, storedDeviceId, storeDeviceId]);
  const next = useCallback(() => {
    if (devices === undefined || devices.length === 0) {
      return;
    }
    setDevice((prev) => {
      const nextDevice = getNext(devices, prev);
      if (nextDevice !== undefined) {
        storeDeviceId(nextDevice.id);
      }
      return nextDevice;
    });
  }, [devices, storeDeviceId]);
  const set = useCallback(
    (device: Device) => {
      setDevice(device);
      storeDeviceId(device.id);
    },
    [storeDeviceId],
  );
  return { device, next, set };
};

const stateIs = (state: Html5QrcodeScannerState) => (scanner: Html5Qrcode) => scanner.getState() === state;
const stateIsNot = (state: Html5QrcodeScannerState) => (scanner: Html5Qrcode) => scanner.getState() !== state;

const canBeStopped = stateIsNot(Html5QrcodeScannerState.NOT_STARTED);
const canBeStarted = stateIs(Html5QrcodeScannerState.NOT_STARTED);
const canBeResumed = stateIs(Html5QrcodeScannerState.PAUSED);
const canBePaused = stateIs(Html5QrcodeScannerState.SCANNING);

/**
 * The size of the scan area as a fraction of the smaller dimension of the video
 * feed. The scan area is a square.
 *
 * Example: Let's say the video feed is 1000x500 and `SCAN_AREA_SIZE` is 0.8.
 * The smaller dimension is 500. The scan area will be 500 * 0.8 = 400 pixels
 * wide and 400 pixels tall.
 */
const SCAN_AREA_SIZE = 0.8;
// copied const from the html5-qrcode library - not publicly accessible
const MIN_QR_BOX_SIZE = 50;
const START_CONFIG = {
  disableFlip: true,
  fps: 1,
  qrbox: (vfWidth, vfHeight) => {
    let size = Math.min(vfWidth, vfHeight) * SCAN_AREA_SIZE;
    // reset size to minimum if it's too small (min size from html5-qrcode library)
    size = size > MIN_QR_BOX_SIZE ? size : MIN_QR_BOX_SIZE;

    return { width: size, height: size };
  },
} satisfies Html5QrcodeCameraScanConfig;
const startScanner = (scanner: Html5Qrcode, deviceId: string, onScan: (code: string) => void) => {
  scanner.start(deviceId, START_CONFIG, onScan, undefined).then(noop).catch(noop);
};

const CONFIG = {
  formatsToSupport: [Html5QrcodeSupportedFormats.QR_CODE, Html5QrcodeSupportedFormats.CODE_128],
  verbose: false,
} satisfies Html5QrcodeFullConfig;
export const useScanner = (elementId: string, deviceId: string | undefined, onScan: (code: string) => void) => {
  const scanner = useRef<Html5Qrcode>();
  const { reportAsInfo } = useHandleErrors();

  useEffect(() => {
    scanner.current = new Html5Qrcode(elementId, CONFIG);
    return () => {
      if (scanner.current === undefined) {
        return;
      }
      if (!canBeStopped(scanner.current)) {
        return;
      }

      try {
        scanner.current.stop();
      } catch (e) {
        // It doesn't check whether the element is still mounted. If it isn't,
        // `removeChild` throws an error. We catch it here and report it as info.
        reportAsInfo(e as Error, ['useScanner', 'error during scanner stop']);
      }
    };
  }, [elementId, reportAsInfo]);
  useEffect(() => {
    try {
      if (scanner.current === undefined) {
        return;
      }
      if (deviceId === undefined) {
        return;
      }

      if (canBeStopped(scanner.current)) {
        scanner.current
          .stop()
          .then(() => {
            if (scanner.current === undefined) {
              return;
            }
            startScanner(scanner.current, deviceId, onScan);
          })
          .catch((e) => reportAsInfo(e as Error, ['useScanner', 'error during deviceChange']));
        return;
      }
      if (canBeStarted(scanner.current)) {
        startScanner(scanner.current, deviceId, onScan);
      }
    } catch (e) {
      reportAsInfo(e as Error, ['useScanner', 'error during scanner start']);
    }
  }, [deviceId, onScan, reportAsInfo]);
  return scanner.current;
};

export const usePause = (scanner: Html5Qrcode | undefined, disabled: boolean) => {
  useEffect(() => {
    if (scanner === undefined) {
      return;
    }
    if (disabled && canBePaused(scanner)) {
      scanner.pause(true);
    }
    if (!disabled && canBeResumed(scanner)) {
      scanner.resume();
    }
  }, [scanner, disabled]);
};
